.container {
    /* background-color: lightgray; */
    padding: 20px;
  }
  
  .image-grid {
    margin-top: 20px;
  }
  
  .image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  /* .image-container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  } */
  
  .content-header {
    text-align: center;
    padding: 20px;
    background-color: #f4f4f4;
  }
  
  .card {
    margin-bottom: 20px;
    border: 1px solid #ddd; 
    cursor: pointer;
  }
  
  .card-header {
    background-color: #f4f4f4;
    border-bottom: 1px solid #ddd;
  }
  
  .card-title {
    margin: 0;
  }
  
  .card-body {
    padding: 20px;
    /* border: 1px solid #ddd; 
    margin-top: 19px; */
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th,
  .table td {
    padding: 10px;
    border: 1px solid #ddd;
  }
  
  .product-image {
    /* max-width: 100%;
    max-height: 200px; */
    /* width: 208px;
    height: 126px; */
    width: 100%;
    height: 250px;
    object-fit: contain;
    border: 1px solid #ddd;
    cursor: pointer;
  }

  .logoImg {
    width: 17%;
    height: 250px;
    height: 155px;
  }
  
  .main-imgStyle {
    width: 100%;
    margin-top: 27px;
    height: 250px;
  }
  
  .product-image-thumbs {
    margin-top: 20px;
  }
  
  .product-image-thumb {
    display: inline-block;
    margin-right: 10px;
  }
  
  .product-image-thumb img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border: 1px solid #ddd;
    cursor: pointer;
  }
  
  .bg-gray {
    background-color: #f4f4f4;
    padding: 10px;
    margin-top: 20px;
  }
  
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .float-sm-right {
    float: right;
  }
  
  .mt-0 {
    margin-top: 0;
  }
  
  .mb-0 {
    margin-bottom: 0;
  }
  
  .my-3 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  .py-2 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .px-3 {
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .mt-4 {
    margin-top: 40px;
  }

  .description-detail {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    margin-top: 20px;
  }
  
  .description-title {
    font-size: 18px;
    font-weight: bold;
  }
  
  .description-text {
    font-size: 18px !important;
    line-height: 2.5;
  }
  

  /* .slider-image-container {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
  } */
  
  .thumbs-container {
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .thumbs-container li {
    margin: 5px;
  }
  

   .slider-image-container {
    border: 2px solid #f0f0f0; /* Set the border color */
    padding: 10px;
    margin-bottom: 10px;
    height: 400px; /* Adjust the height as needed */
    width: 100%; /* Adjust the width as needed */
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .slider-image-container img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    border: 2px solid #f0f0f0; 
  }

  .carousel .slider-wrapper img {
    max-height: 400px;
    max-width: 100%;
    object-fit: contain;
  }

  #image-container {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 20px;
    margin-top: 27px;
  }
  
  .product-image-thumbs {
    display: flex;
    justify-content: space-between;
  }
  
  .product-image-thumb {
    border: 1px solid #ccc;
    padding: 5px;
    width: 20%;
    margin-right: 10px;
    background-color: red;
  }
  
.btnStyle{
  background-color: black;
  border: none;
  color: white;
  }

.btnStyle:hover{
    color: aqua;
}

.previous {
    background-color: #f1f1f1;
    color: black;
  }
.description{
    font-size: 12px !important;
}