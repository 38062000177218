.cardStyle{
    padding: '20px';
    border: '1px solid #ddd';
    border-radius:'5px';
    margin-bottom: '20px';
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    overflow: 'hidden'
}
.cardStyle > div:hover{
    box-shadow: 0 4px 15px 8px rgb(0 0 0 / 20%);
    cursor: pointer;
}

.customicon {
    color: green;
}

.card-content{
    text-align: center;
    color: grey;
}
.totalbtn{
float:right;
font-size: 27px;
}
.selectDropdown{
    color: black !important;
    height: 46px !important;
}
form{
    width: 100%;
    text-align: left;
}